<!-- 新闻资讯-->
<template>
    <div class="news-wrap">
        <div class="news-content">
            <div class="news-box" v-for="(item,index) in data.value" :key="index">
                    <h3><span><a href="javascript:void(0)" @click="$router.pushToTab('cms/article/listother-'+ item.id+'?level=1' )">&gt;</a></span>{{ item.title }}</h3>
                    <ul>
                        <li v-for="(citem,cindex) in item.items" :key="cindex"><a href="javascript:void(0)" @click="$router.pushToTab('cms/article-' + citem.id)"><span class="date">2021-01-26</span>{{ citem.title }}</a></li>
                    </ul>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "news-style-1",
    components: {},
    computed: {},
    props: ['data'],
    data() {
		return {};
	},
    mounted() {
    }
}
</script>

<style lang="scss">
.news-wrap{
    width: 100%;
    border-radius: 5px;
    .news-content{
        width: 100%;
        overflow: hidden;
        .news-box{
            padding: 10px;
            width: 24%;
            margin-right: 1%;
            background-color: #ffffff;
            border-radius: 5px;
            border: 1px #eeeeee solid;
            min-height: 200px;
            float: left;
            h3{ display: block; line-height: 30px; padding: 5px 20px; font-size: 16px; 
                span{ float: right; font-size: 12px; color: #ffffff; font-weight: normal;}
            }
            ul,li{ display: block; list-style: none;;}
            ul li{
                line-height: 30px; border-bottom: 1px #eeeeee solid; padding: 5px 20px; cursor: pointer;
                a > span{ float: right; color: #cccccc;}
                &:hover{ color:#e04b34;}
            }
        }
    }
    .clear{ clear: both;}
}
</style>
