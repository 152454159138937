<template>
    <div>
        <!-- 首页固定区 -->
        <div class="index-wrap">
            <div class="index">
                <div class="banner">
                    <el-carousel height="436px" arrow="hover" @change="handleChange">
                        <el-carousel-item v-for="item in adList" :key="item.adv_id">
                            <el-image :src="$img(item.adv_image)" fit="cover"
                                      @click="$router.pushToTab(item.adv_url.url)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>

        <!--排行榜-->
        <div class="content"  v-if="floorList.ranking.length > 0">
            <div class="content-div"  v-for="(item, index) in floorList.ranking" :key="index">
                <ranking-style-1 v-if="item.block_name == 'floor-style-4'"/> <!-- 充值排行 -->
                <ranking-style-3 v-if="item.block_name == 'floor-style-7'"/> <!-- 全国 -->
            </div>
        </div>

        <!--新闻资讯-->
        <div class="content"  v-if="floorList.news.length > 0">
            <div class="content-div"  v-for="(item, index) in floorList.news" :key="index">
                <news-style-1 v-if="item.block_name == 'floor-style-9'"/>
            </div>
        </div>

        <!--城市分站-->
        <div class="content"  v-if="floorList.localbranch.length > 0">
            <div class="content-div"  v-for="(item, index) in floorList.localbranch" :key="index">
                <localbranch-style-1 v-if="item.block_name == 'floor-style-5'"/> <!-- 城市分站 -->
                <localbranch-style-2 v-if="item.block_name == 'floor-style-8'"/> <!-- 城市分站-全国 -->
            </div>
        </div>

        <!-- 楼层 -->
        <div class="content">
            <!-- 领券中心 -->
            <div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0">
                <div class="coupon">
                    <div class="coupon-title">
                        <p class="coupon-font">领券中心</p>
                        <p class="coupon-en">coupon</p>
                        <p class="coupon-more" @click="$router.push('/coupon')">
                            <span>更多</span>
                            <i class="iconfont iconarrow-right"></i>
                        </p>
                    </div>
                    <ul class="coupon-list">
                        <li v-for="(item, index) in couponList" :key="index">
                            <p class="coupon-price ns-text-color">
                                ￥
                                <span>{{ item.money }}</span>
                            </p>
                            <p class="coupon-term">满{{ item.at_least }}可用</p>
                            <p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
                                <span v-if="item.useState == 0">立即领取</span>
                                <span v-else>去使用</span>
                                <i class="iconfont iconarrow-right"></i>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <!--广告-->
            <div class="content-div" v-if="adOneBarList.length > 0 && adOneBarListShow">
                <div class="ad-wrap" style="display: block">
                    <div class="ad-one-bar-img" style="margin-top:15px" v-for="(item, index) in adOneBarList" :key="index">
                        <el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
                                  @click="$router.pushToTab(item.adv_url.url)"></el-image>
                    </div>
                </div>
            </div>
            <div class="content-div" v-if="(adLeftList.length > 0 || adRightList.length > 0) && adLeftListShow && adRightListShow">
                <div class="ad-wrap">
                    <div class="ad-big">
                        <div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
                                      @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                    <div class="ad-small">
                        <div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)"
                                      @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-div" v-if="adFourNumList.length > 0 && adFourNumListShow">
                <div class="ad-wrap">
                    <div class="ad-four-num">
                        <div class="ad-four-num-img" v-for="(item, index) in adFourNumList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)"
                                      @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 限时秒杀 -->
            <div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0">
                <div class="seckill-wrap">
                    <div class="seckill-time">
                        <div class="seckill-time-left">
                            <i class="iconfont iconmiaosha1 ns-text-color"></i>
                            <span class="seckill-time-title ns-text-color">限时秒杀</span>
                            <span class="seckill-time-text">{{ seckillText }}</span>
                            <count-down class="count-down" v-on:start_callback="countDownS_cb()"
                                        v-on:end_callback="countDownE_cb()"
                                        :currentTime="seckillTimeMachine.currentTime"
                                        :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
                                        :dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
                        </div>
                        <div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
                            <span>更多商品</span>
                            <i class="iconfont iconarrow-right"></i>
                        </div>
                    </div>
                    <div class="seckill-content">
                        <vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
                            <ul class="item" :style="{ width: 220 * listData.length + 'px' }">
                                <li v-for="(item, index) in listData" :key="index">
                                    <div class="seckill-goods"
                                         @click="$router.pushToTab('/promotion/seckill-' + item.goods_id)">
                                        <div class="seckill-goods-img">
                                            <img :src="$img(item.goods_image, { size: 'mid' })" @error="imageError(index)" />
                                        </div>
                                        <h3>{{ item.goods_name }}</h3>
                                        <p class="desc">{{ item.introduction }}</p>
                                        <p class="price">
                                            <span class="num">￥{{ item.seckill_price }} </span>
                                            <del>￥{{ item.price }}</del>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>

            <!-- 楼层区 -->
            <div class="content-div" v-if="floorList.floor.length > 0">
                <div class="floor">
                    <div v-for="(item, index) in floorList.floor" :key="index" class="floor_item">
                        <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
                        <floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
                        <floor-style-2_2 v-if="item.block_name == 'floor-style-2_2'" :data="item" />
                        <floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
                        <newsStyle1 v-if="item.block_name == 'news-style-1'" :data="item" />
                        <projectsupport-style-1 v-if="item.block_name == 'floor-style-10'" :data="item" />
                    </div>
                </div>
            </div>

            <!-- 浮层区 -->
            <div class="floatLayer-wrap" v-if="floatLayer.is_show">
                <div class="floatLayer">
                    <div class="img-wrap">
                        <img :src="$img(floatLayer.img_url)" v-if="floatLayer.link.url" @click="$router.pushToTab(floatLayer.link.url)" />
                        <img :src="$img(floatLayer.img_url)" v-else />
                    </div>
                    <i class="el-icon-circle-close" @click="closeFloat"></i>
                </div>
            </div>

            <!-- 悬浮搜索 -->
            <div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
                <div class="header-search">
                    <el-row>
                        <el-col :span="6">
                            <router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
                        </el-col>
                        <el-col :span="13">
                            <div class="in-sousuo">
                                <div class="sousuo-box">
                                    <el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="goods">商品</el-dropdown-item>
                                            <el-dropdown-item command="shop">店铺</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <input type="text" :placeholder="defaultSearchWords" v-model="keyword"
                                           @keyup.enter="search" maxlength="50" />
                                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="cart-wrap">
                                <router-link class="cart" to="/cart">
                                    <span>我的购物车</span>
                                    <el-badge v-if="cartCount" :value="cartCount" type="primary"><i
                                            class="iconfont icongouwuche"></i></el-badge>
                                    <i v-else class="iconfont icongouwuche"></i>
                                </router-link>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //楼层商品
    import floorStyle1 from "./components/floor-style-1.vue"
    import floorStyle2 from "./components/floor-style-2.vue"
    import floorStyle2_2 from "./components/floor-style-2_2.vue"
    import floorStyle3 from "./components/floor-style-3.vue"
    //排行榜
    import rankingStyle1 from "./components/ranking-style-1.vue"
    import rankingStyle3 from "./components/ranking-style-3.vue"
    //项目帮扶
    import projectsupportStyle1 from "./components/projectsupport-style-1.vue"
    //新闻资讯
    import newsStyle1 from "./components/news-style-1.vue"
    //城市分站
    import localbranchStyle1 from "./components/localbranch-style-1.vue"
    import localbranchStyle2 from "./components/localbranch-style-2.vue"
    import index from "./_index/index.js?v=0.0.1"

    export default {
        name: "index",
        components: {
            floorStyle1,
            floorStyle2,
            floorStyle2_2,
            floorStyle3,
            rankingStyle1,
            rankingStyle3,
            projectsupportStyle1,
            newsStyle1,
            localbranchStyle1,
            localbranchStyle2
        },
        computed: {},
        data() {
            return {
            };
        },
        mixins: [index],
        mounted() {
        },
        methods: {
            supportMoew() {
                this.$router.push('/support')
            },
            supporttoDetail(site_id) {
                this.$router.pushToTab({
                    path: "/support-" + site_id
                })
            },
            RegiontoDetail(site_id) {
                this.$router.pushToTab({
                    path: "/region-" + this.childregion_id,
                    query:{
                        city_id: this.parentValue,
                        district_id: this.childValue,
                    }
                })
            },
            regionMore() {
                this.$router.push('/region')
            },
            bgClass(i) {
                if (i === 0) {
                    return "#f8c168"
                } else if (i === 1) {
                    return "#c9c9c9"
                } else if (i === 2) {
                    return "#a1c0da"
                } else {
                    return "#c9c9c9"
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './_index/index.scss';
</style>

<style lang="scss">
    .content .floor-head{
        height:45px;
        margin: 0px 0px 20px;
        text-align: center;
        position:relative;
        padding:30px 0px 0px;
        h2{
            font-size: 28px;
            line-height: 45px;
            color: #301D1D;
            img{
                margin: 0px 10px;
                width: 46px;
                height: 26px;
            }
        }
    }


    .count-down {
        text-align: center;
        span {
            display: inline-block;
            text-align: center;
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #D81E01;
            font-size: 18px;
            line-height: 24px;
        }
    }
    .el-carousel__arrow{background-color: rgb(31 45 61 / 40%)}
    .el-carousel__arrow--right{left:50%;margin-left: 550px}
    .el-carousel__arrow--left{left:50%;margin-left: -390px;}
</style>
