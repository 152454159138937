<template>
	<div class="floor-style-2_2">
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img :src="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
		<div class="body-wrap">
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="goods-wrap">
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" /></div>
						<h3>
<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.goods_name }}</h3>
						<span class="tag taggreen" v-if="item.recommend_way ==1">新品</span>
						<span class="tag tagblue" v-if="item.recommend_way ==2">精品</span>
						<span class="tag" v-if="item.recommend_way ==3">推荐</span>
<!--						<p class="desc">{{ item.introduction }}</p>-->
						<p class="price">
							<span class="num">￥{{ item.discount_price }}</span>
<!--							<del>{{ item.market_price }}元</del>-->
						</p>
					</div>
				</li>
			</ul>
			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-2_2',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
	 // console.log('==', this.data.value.goodsList)
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-2_2 {
	.head-wrap {
		padding: 20px 20px 10px 20px;
		background-color: #fff;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		h2 {
			line-height: 48px;
			color: #333;
			padding: 0px 0px;
			font-size: 20px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;

			span {
				border: 3px solid $base-color;
				border-radius: 4px;
				height: 14px;
				margin-right:10px;
				display: inline-block;
			}
		}
		p {
			color: #b0b0b0;
			padding: 0 10px;
			font-size: 14px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.body-wrap {
		background-color: #fff;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		position: relative;
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			margin:0px 20px 20px;
			width: 100%;
			li {
				width: 25%;
				height:350px;
				overflow: hidden;
				margin: 0 0 15px;
				background: #fff;
				cursor: pointer;
				position: relative;
				padding:0;
				transition: all 0.2s linear;
				.goods-wrap{
					margin: 0 10px 20px;
					padding: 15px 0px;
					&:hover {
						z-index: 2;
						-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						-webkit-transform: translate3d(0, -2px, 0);
						transform: translate3d(0, -2px, 0);
					}

					.img-wrap {
						width: 100%;
						height: 250px;
						margin: 0px auto;
						text-align: center;
						/*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
						/*border-radius: 16px;*/
						overflow: hidden;
					}
					h3 {
						font-size: 14px;
						font-weight: normal;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						height: 44px;
						-webkit-box-orient: vertical;
						margin: 10px 15px 3px;
					}
					.tag {
						display: inline-block;
						position: absolute;
						top: 18px;
						right: 24px;
						z-index: 1;
						font-size: 12px;
						width: 36px;
						background-color: #e84440;
						color: #fff;
						text-align: center;
						border-radius: 2px;
						letter-spacing: 2px;
					}
					.tag.taggreen {
						background-color: #07b85a;
					}
					.tag.tagblue {
						background-color: #0077FF;
					}

					.desc {
						margin:2px 15px;
						font-size: 13px;
						line-height:14px;
						color: #b0b0b0;
						text-align: center;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
					.price {
						margin:5px 15px 0px;
						font-size: 16px;
						/*text-align: center;*/
						font-weight: 700;
						color: $base-color;
						del {
							margin-left: 0.5em;
							color: #b0b0b0;
						}
					}
				}
			}
		}
	}
	.clear{
		clear: both;
	}
	.bottom-wrap {
		margin-top: 20px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}
</style>
